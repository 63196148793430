<template>
  <app-card-code :code="codeSnippets.formLayoutTabs" name="vistaInformes">
    <h3 class="ml-5">
      Informes
      <v-icon
        title="Refrescar vista"
        @click="refrechData"
      >
        {{ icons.mdiFindReplace }}
      </v-icon>
    </h3>
    <v-row class="mb-10 mt-5 ml-3 mr-3">
      <!-- columna rut -->
      <v-col
        md="9"
        cols="8"
      >
        <v-select
          name = 'selectInforme'
          v-model="informe"
          :items="optionsinformes"
          label="Informes"
          item-text="nombreInforme"
          return-object
          dense
          @change="selectInforme(informe)"
        ></v-select>
      </v-col>

      <!-- columba boton buscar -->
      <v-col
        md="1"
        cols="1"
      >
        <v-btn
          v-if="ativeBoton"
          name = 'botonGenerar'
          class="d-flex justify-end"
          color="primary"
          x-small
          text
          :loading="loading"
          :disabled="loading"
          @click="formValidate()"
        >
          Generar
          <v-icon class="ml-2">
            {{ icons.mdiCloudPrint }}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-form
      v-if="activarForm == true"
      ref="formInforme"
    >
      <v-row class="mb-10 mt-5 ml-3 mr-3">
        <!-- columna rut -->
        <v-col
          md="6"
          cols="12"
        >
          <v-text-field
            name = 'fechaDesde'
            v-model="fechaDesde"
            dense
            hide-details
            label="Fecha desde"
            type="date"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>

        <!-- columna producto -->
        <v-col
          md="6"
          cols="12"
          desabled
        >
          <v-text-field
            name = 'fechaHasta'
            v-model="fechaHasta"
            dense
            hide-details
            label="Fecha Hasta"
            type="date"
            :rules="[validators.required]"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-form>

    <v-snackbar
      v-model="isFormSubmittedSnackbarVisible2"
      right
      top
      :color="colorSnackbar"
      timeout="2000"
    >
      {{ textSnackbar }}
      <!-- <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="isFormSubmittedSnackbarVisible2 = false"
        >
          <v-icon>{{ icons.mdiCloseThick }}</v-icon>
        </v-btn>
      </template> -->
    </v-snackbar>
  </app-card-code>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiClose, mdiThumbUp, mdiThumbDown, mdiAccountConvert, mdiCloudPrint, mdiFindReplace } from '@mdi/js'
import { required } from '@core/utils/validation'
import FileSaver from 'file-saver'
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import { formLayoutTabs, dialogFullScreen } from './code'
import { InformeServices } from '../../../service/informes.services'

export default {
  components: {
    AppCardCode,
  },
  setup() {
    const formInforme = ref(null)
    const loading = ref(false)

    const optionsinformes = ref([])
    const informes = ref('')

    const isFormSubmittedSnackbarVisible2 = ref(false)
    const colorSnackbar = ref('')
    const textSnackbar = ref('')
    const activarForm = ref(false)
    const ativeBoton = ref(false)
    const fechaHasta = ref('')
    const fechaDesde = ref('')
    const informe = ref('')

    return {
      codeSnippets: {
        formLayoutTabs,
        dialogFullScreen,
      },
      icons: {
        mdiClose,
        mdiThumbUp,
        mdiThumbDown,
        mdiAccountConvert,
        mdiCloudPrint,
        mdiFindReplace,
      },
      validators: {
        required,
      },
      loading,
      optionsinformes,
      informes,
      activarForm,
      informe,

      isFormSubmittedSnackbarVisible2,
      colorSnackbar,
      textSnackbar,
      ativeBoton,
      fechaHasta,
      fechaDesde,
      formInforme,
    }
  },
  created() {
    this.listaSolicitudes()
  },
  methods: {
    formatFecha(valor) {
      const dato = valor.split('-')

      return typeof dato === typeof [] ? `${dato[2]}-${dato[1]}-${dato[0]}` : 'Sin información'
    },
    async listaSolicitudes() {
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo
      this.solicitudes = []
      await InformeServices.getLista(dataUser).then(resp => {
        if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
          this.optionsinformes = resp.data.data
        } else {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = resp.data.message
          this.colorSnackbar = 'error'
        }
      })
    },

    selectInforme(item) {
      console.log(item)
      if (item.requiereFechas === true) {
        this.informe = item
        this.activarForm = true
        this.ativeBoton = true
      } else {
        this.informe = item
        this.activarForm = false
        this.ativeBoton = true
      }
    },
    formValidate() {
      if (this.informe.requiereFechas === true) {
        const isFormValid = this.formInforme.validate()

        if (!isFormValid) return
        const fechaInicio = new Date(this.fechaDesde).getTime()
        const fechaFin = new Date(this.fechaHasta).getTime()

        const diferencia = fechaFin - fechaInicio
        const dias = diferencia / (1000 * 60 * 60 * 24)

        if (dias > 62) {
          this.isFormSubmittedSnackbarVisible2 = true
          this.textSnackbar = 'La fecha no puede ser mayor a 62 dias'
          this.colorSnackbar = 'error'

          this.fechaDesde = ''
          this.fechaHasta = ''

          return
        }
      } else {
        this.fechaDesde = ''
        this.fechaHasta = ''
      }

      this.generarInforme()
    },

    async generarInforme() {
      const dataUser = JSON.parse(localStorage.getItem('userData')).codigo

      const data = {
        usuario: dataUser,
        informe: this.informe.informeCodigo,
        fechaInicio: this.fechaDesde,
        fechaFin: this.fechaHasta,
      }

      await InformeServices.getGenerar(data).then(resp => {
        // if (resp.data.statusCode === 201 || resp.data.statusCode === 200) {
        FileSaver.saveAs(resp.data, `${resp.headers['nombre-archivo']}`)

        // } else {
        //   this.isFormSubmittedSnackbarVisible2 = true
        //   this.textSnackbar = resp.data.message
        //   this.colorSnackbar = 'error'
        // }
      })
    },
    refrechData() {
      this.fechaDesde = ''
      this.fechaHasta = ''
      this.informe = ''
      this.activarForm = false
      this.ativeBoton = false
    },
  },
}
</script>
<style scoped>
.v-btn--fab.v-size--x-small {
  height: 20px !important;
  width: 20px !important;
}
.accion {
  margin-bottom: 1px;
}
a {
  text-decoration: none;
}
.textos {
  padding: 5px;
  font-size: 13px;
}
.header-inf-basica {
  background: linear-gradient(98deg, #67afed, var(--v-primary-base) 94%);
  color: white !important;
  font-weight: 300 !important;
  text-align: center;
  border-radius: 10px;
}
.header-inf-basica-text {
  color: white !important;
  font-weight: 300 !important;
}
</style>
