import axios from 'axios'
import { headers } from './header'

export const InformeServices = {
  getLista,
  getGenerar,
}

const URL_API_ADMIN = `${process.env.VUE_APP_API_HOST_ADMIN}/api/v1`
const URL_API_VENTA = `${process.env.VUE_APP_API_HOST_VENTA}/api/v1`

async function getLista(data) {
  const requestOptions = {
    method: 'GET',
    url: `${URL_API_VENTA}/informes/${data}`,
    headers: headers(),
    withCredentials: true,
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}

async function getGenerar(data) {
  const datos = {
    fechaInicio: data.fechaInicio,
    fechaFin: data.fechaFin,
  }
  const requestOptions = {
    method: 'POST',
    url: `${URL_API_VENTA}/informes/descargar/${data.usuario}/${data.informe}`,
    headers: headers(),
    withCredentials: true,
    data: datos,
    responseType: 'blob',
  }

  return axios(requestOptions)
    .then(res => res)
    .catch(err => err.response)
}
